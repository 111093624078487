import { trpc } from '@/lib/providers/trpc';
import { useParams } from 'react-router-dom';

export function useTenantId() {
	const { tenantId } = useParams();
	return tenantId;
}

export function useTenantIdSafe() {
	const { tenantId } = useParams();
	return tenantId as string;
}

export function useTenant() {
	const tenantId = useTenantId();
	return trpc.tenantsById.useQuery({ tenantId: tenantId! }, { enabled: !!tenantId });
}

export function useTenantFeatureFlags() {
	const tenant = useTenant();
	return {
		enableGroups: tenant.data?.enableGroups ?? false,
		enableDepartments: tenant.data?.enableDepartments ?? false,
		enableProjects: tenant.data?.enableProjects ?? false,
		enableLocations: tenant.data?.enableLocations ?? false,
		isLoading: !tenant.data,
	};
}
