import { PropsWithChildren, useState } from 'react';
import { ScrollArea } from '../ui/scroll-area';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { UseFieldArrayMove } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDrag } from '@/hooks/useDrag';
import { cn } from '@/lib/utils';
import { Button } from '../ui/button';
import {
	AlertDialogHeader,
	AlertDialogFooter,
	AlertDialog,
	AlertDialogContent,
	AlertDialogTitle,
	AlertDialogDescription,
	AlertDialogCancel,
	AlertDialogAction,
} from '../ui/alert-dialog';
import { PlusIcon, Trash2Icon } from 'lucide-react';
import { TypographyH4 } from '../ui/typography';

export function FormBaseLayout({ children }: PropsWithChildren) {
	return <div className="flex flex-1 flex-col gap-4 overflow-hidden md:flex-row">{children}</div>;
}
interface FormBaseProps {
	className?: string;
}

export function FormBaseSidebar({ children, className }: PropsWithChildren<FormBaseProps>) {
	const [sectionsAnimationRef] = useAutoAnimate();
	return (
		<ScrollArea className={cn('flex rounded-md bg-muted px-4 py-2', className)}>
			<div className="flex flex-1 flex-col gap-y-1 overflow-hidden" ref={sectionsAnimationRef}>
				{children}
			</div>
		</ScrollArea>
	);
}
export function FormBaseMain({ children, className }: PropsWithChildren<FormBaseProps>) {
	return <div className={cn('flex flex-1 flex-grow flex-col gap-y-2 overflow-auto', className)}>{children}</div>;
}
export function FormBaseMainContent({ children, className }: PropsWithChildren<FormBaseProps>) {
	const [contentAnimationRef] = useAutoAnimate();
	return (
		<ScrollArea className={cn('flex h-64 flex-1 flex-col overflow-y-auto', className)}>
			<section ref={contentAnimationRef}>{children}</section>
		</ScrollArea>
	);
}
export function FormBaseMainHeader({ children, className }: PropsWithChildren<FormBaseProps>) {
	const [headerAnimationRef] = useAutoAnimate();
	return (
		<header ref={headerAnimationRef} className={cn('flex flex-col pb-1', className)}>
			{children}
		</header>
	);
}
export function FormBaseMainFooter({ children, className }: PropsWithChildren<FormBaseProps>) {
	return <footer className={cn('flex flex-col pt-1', className)}>{children}</footer>;
}

export function FormBaseSectionTitle({ children, className }: PropsWithChildren<FormBaseProps>) {
	return <TypographyH4 className={cn(className, 'text-lg')}>{children}</TypographyH4>;
}

interface FormBaseSidebarSectionButtonProps {
	children: string;
	onClick: () => void;
	isActive?: boolean;
	move?: UseFieldArrayMove;
	onSectionDelete?: (idx: number) => void;
	sectionIdx: number;
}

export function FormBaseSidebarSectionButton({ children, onClick, isActive, sectionIdx, move, onSectionDelete }: FormBaseSidebarSectionButtonProps) {
	const { t } = useTranslation();
	const { draggableProps, isDragging } = useDrag({
		onDrag: move!,
		currentIndex: sectionIdx,
		group: 'sections',
	});
	const [open, setOpen] = useState(false);

	const draggableObj = move ? draggableProps : {};
	return (
		<div
			{...draggableObj}
			className={cn(
				'group/form-btn flex w-64 items-center justify-between',
				isDragging ? 'border-2 border-dashed border-red-500 bg-primary/10' : 'background-auto',
			)}
		>
			<button type="button" onClick={onClick} className={cn('flex h-10 flex-1 items-center justify-start gap-x-2')}>
				<span
					className={cn(
						'truncate text-start text-sm font-semibold transition-colors group-hover/form-btn:underline',
						isActive ? 'font-semibold text-primary' : 'text-primary/60',
					)}
				>
					{children || '...'}
				</span>
			</button>

			{onSectionDelete && (
				<div>
					<Button className="ml-1 cursor-pointer p-0" type="button" variant="ghost" onClick={() => setOpen(true)}>
						<Trash2Icon className="h-5 w-5 text-primary" />
					</Button>
					<AlertDialog open={open} onOpenChange={setOpen}>
						<AlertDialogContent>
							<AlertDialogHeader>
								<AlertDialogTitle>{t('confirm_delete_title')}</AlertDialogTitle>
								<AlertDialogDescription>{t('confirm_delete_description', { name: children })}</AlertDialogDescription>
							</AlertDialogHeader>
							<AlertDialogFooter>
								<AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
								<AlertDialogAction asChild>
									<Button variant="destructive" onClick={() => onSectionDelete(sectionIdx)}>
										{t('delete')}
									</Button>
								</AlertDialogAction>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialog>
				</div>
			)}
		</div>
	);
}

interface FormBaseSidebarSectionAddButtonProps {
	onClick: () => void;
}

export function FormBaseSidebarSectionAddButton({ onClick }: FormBaseSidebarSectionAddButtonProps) {
	const { t } = useTranslation();
	return (
		<Button type="button" variant="link" onClick={onClick} className="flex justify-between p-0 text-blue-600">
			<span className="text-sm">{t('new_section')}</span>
			<PlusIcon className="h-5 w-5" />
		</Button>
	);
}
