import React, { useEffect } from 'react';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { FormViewerSectionContent } from './form-viewer-section-content';
import { FormViewerModes, FormViewerShemaType } from './form-viewer-types';
import { useTranslation } from 'react-i18next';
import { formTemplatesCommonViewFormContentInputSchema } from '@timp/server/src/schemas/form-templates-common.schema';
import {
	FormBaseMainFooter,
	FormBaseLayout,
	FormBaseMain,
	FormBaseMainContent,
	FormBaseSidebar,
	FormBaseSidebarSectionButton,
	FormBaseMainHeader,
	FormBaseSectionTitle,
} from '../form-builders/form-base-layout';
import { TypographyMuted } from '../ui/typography';

const noop = () => {};

type Props = {
	isSubmitting?: boolean;
	mode: FormViewerModes;
	form: FormViewerShemaType;
	onSubmit?: (form: FormViewerShemaType) => void;
	submitButtonText?: string;
	onDirtyChange?: (isDirty: boolean) => void;
};

export function FormViewer(props: Props) {
	const { onDirtyChange } = props;
	const { t } = useTranslation();

	const [activeSectionIdx, setActiveSectionIdx] = useState(0);

	const form = useForm<FormViewerShemaType>({
		resolver: zodResolver(formTemplatesCommonViewFormContentInputSchema),
		values: props.form,
	});
	const errors = form.formState.errors;

	const { control } = form;

	const { fields: sections } = useFieldArray({
		control,
		name: 'sections',
	});

	const activeSection = sections[activeSectionIdx];

	const isFirstSection = activeSectionIdx === 0;
	const isLastSection = activeSectionIdx === sections.length - 1;

	/**
	 * Focus the first element with an error when the form is submitted
	 */
	useEffect(() => {
		const sectionWithErrorIdx = errors?.sections?.findIndex?.((section) => !!section);
		if (typeof sectionWithErrorIdx === 'number') {
			const element = errors?.sections?.at?.(sectionWithErrorIdx)?.elements?.find?.((el) => !!el);
			if (element) {
				const el = element?.root ?? element;
				setActiveSectionIdx(sectionWithErrorIdx);
				el?.ref?.focus?.();
			}
		}
	}, [errors]);

	useEffect(() => {
		if (onDirtyChange) {
			onDirtyChange(form.formState.isDirty);
		}
	}, [form.formState.isDirty, onDirtyChange]);

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(props.onSubmit ?? noop)} className="flex flex-1 flex-col overflow-hidden">
				<FormBaseLayout>
					{sections.length > 1 && (
						<FormBaseSidebar className="hidden md:flex">
							{sections.map((section, index) => {
								return (
									<FormBaseSidebarSectionButton
										key={section.id}
										onClick={() => setActiveSectionIdx(index)}
										isActive={index === activeSectionIdx}
										sectionIdx={index}
									>
										{section.name}
									</FormBaseSidebarSectionButton>
								);
							})}
						</FormBaseSidebar>
					)}

					<FormBaseMain>
						<FormBaseMainHeader>
							<div className="flex items-center justify-between" key={activeSection.id}>
								<FormBaseSectionTitle>{activeSection.name}</FormBaseSectionTitle>
								{sections.length > 1 && (
									<TypographyMuted className="min-w-24 text-nowrap md:hidden">
										({t('page')} {activeSectionIdx + 1}/{sections.length})
									</TypographyMuted>
								)}
							</div>
						</FormBaseMainHeader>
						<FormBaseMainContent>
							{sections.length > 0 ? (
								<FormViewerSectionContent key={activeSectionIdx} sectionIndex={activeSectionIdx} control={control} mode={props.mode} />
							) : (
								<span className="text-center">{t('no_sections_found')}</span>
							)}
						</FormBaseMainContent>

						<FormBaseMainFooter>
							<div className="flex w-full justify-end gap-x-2">
								{sections.length > 1 && (
									<>
										<Button
											type="button"
											variant="outline"
											disabled={isFirstSection || props.isSubmitting}
											onClick={() => {
												setActiveSectionIdx((c) => Math.max(c - 1, 0));
											}}
										>
											{t('back')}
										</Button>
										<Button
											type="button"
											variant="outline"
											disabled={isLastSection || props.isSubmitting}
											onClick={() => {
												setActiveSectionIdx((c) => Math.min(c + 1, sections.length - 1));
											}}
										>
											{t('next')}
										</Button>
									</>
								)}

								{props.mode === 'submit' && (
									<Button type="submit" isLoading={props.isSubmitting}>
										{props.submitButtonText ?? t('submit')}
									</Button>
								)}
							</div>
						</FormBaseMainFooter>
					</FormBaseMain>
				</FormBaseLayout>
			</form>
		</Form>
	);
}
