import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { TypographyH3 } from '../ui/typography';
import { LinkButton } from '../buttons/link-button';
import { ChevronLeft, MenuIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Spinner } from '../icons/spinner';
import { TRPCError } from '@/lib/providers/trpc';
import { Button } from '../ui/button';
import { XIcon } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { ScrollArea } from '../ui/scroll-area';

type SizeType = 'container' | 'full';

interface PageProps {
	className?: string;
	isLoading?: boolean;
	error?: Error | TRPCError | null;
	size?: SizeType;
}

export const PageContext = createContext<{ size: SizeType }>(null!);

export function Page({ children, isLoading, className, error, size = 'full' }: PropsWithChildren<PageProps>) {
	return (
		<PageContext.Provider
			value={{
				size,
			}}
		>
			<div className={cn('flex h-full flex-1 flex-col gap-y-4 overflow-hidden py-4', className)}>
				{isLoading && (
					<div className="flex h-full w-full items-center justify-center">
						<Spinner size="lg" />
					</div>
				)}
				{error && (
					<div className="flex h-full w-full items-center justify-center">
						<span className="text-destructive">{error.message}</span>
					</div>
				)}
				{!isLoading && !error ? children : null}
			</div>
		</PageContext.Provider>
	);
}
const usePageContext = () => {
	return useContext(PageContext);
};

interface PageToolbarProps {
	className?: string;
}
export function PageToolbar({ children, className }: PropsWithChildren<PageToolbarProps>) {
	const { size } = usePageContext();

	return (
		<div
			className={cn(
				'grid min-h-10 flex-none auto-cols-auto grid-flow-col flex-wrap gap-x-4 overflow-auto',
				size === 'container' && 'container',
				size === 'full' && 'px-6',
				className,
			)}
		>
			{children}
		</div>
	);
}

interface PageTitleProps {
	backLink?: true | string;
	className?: string;
}
export function PageTitle({ children, className, backLink }: PropsWithChildren<PageTitleProps>) {
	const location = useLocation();
	let backButtonPath: string | null = null;

	if (backLink) {
		if (backLink === true && location.state?.from) {
			backButtonPath = location.state.from;
		} else if (typeof backLink === 'string') {
			backButtonPath = backLink;
		} else {
			backButtonPath = '../';
		}
	}

	return (
		<div className={cn('flex items-center gap-x-4 text-nowrap', className)}>
			{backButtonPath && (
				<LinkButton to={backButtonPath} size="icon" variant="secondary" data-testid="back-button-link">
					<ChevronLeft />
				</LinkButton>
			)}
			<TypographyH3 className="flex items-center gap-x-2 text-xl md:text-2xl">{children}</TypographyH3>
		</div>
	);
}

interface PageActionsProps {}
export function PageActions({ children }: PropsWithChildren<PageActionsProps>) {
	return <div className="flex flex-nowrap items-center gap-4 justify-self-end">{children}</div>;
}

interface PageSidebarProps {}
export function PageSidebar({ children }: PropsWithChildren<PageSidebarProps>) {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();

	// Closes sidebar whenever a route is changed
	useEffect(() => {
		setIsOpen(false);
	}, [location.pathname]);

	return (
		<div className={cn('relative z-50 h-full md:w-72')}>
			<Button variant="outline" className="absolute left-0 top-0 size-7 items-center justify-center p-0 md:hidden" onClick={() => setIsOpen((c) => !c)}>
				<MenuIcon className="size-5 text-primary" strokeWidth={1.5} />
			</Button>
			<nav
				className={cn(
					isOpen && 'left-0',
					!isOpen && '-left-72',
					'absolute z-50 h-full w-72 border-r border-background/5 bg-primary transition-all dark:border-primary/5 dark:bg-background md:static md:left-auto md:z-auto',
				)}
			>
				<ScrollArea className="max-h-full overflow-auto">
					<div className="relative flex flex-col gap-y-8 p-4">
						<Button variant="link" className="absolute right-1 block size-7 p-0 md:hidden" onClick={() => setIsOpen((c) => !c)}>
							<XIcon className="w-full text-background" />
						</Button>
						{children}
					</div>
				</ScrollArea>
			</nav>
			{isOpen && (
				// Blurred background
				<div
					className={cn(
						'absolute inset-0 z-20 h-screen w-screen bg-background/80 backdrop-blur-sm md:pointer-events-none md:static md:z-auto md:h-auto md:w-auto',
					)}
					onClick={() => setIsOpen(false)}
				/>
			)}
		</div>
	);
}

interface PageContentProps {
	rootClassName?: string;
	className?: string;
	scroll?: boolean;
}
export function PageContent({ children, className, rootClassName, scroll = false }: PropsWithChildren<PageContentProps>) {
	const { size } = usePageContext();
	return (
		<div className={cn('group flex flex-1 flex-col gap-y-4', scroll && 'overflow-y-auto', !scroll && 'overflow-y-hidden', rootClassName)}>
			<main className={cn('flex flex-1 flex-col', size === 'container' && 'container', size === 'full' && 'px-6', !scroll && 'overflow-hidden', className)}>
				{children}
			</main>
		</div>
	);
}
