import type { DB } from '$/services/kysely';
import { Insertable, Updateable } from 'kysely';
import { z } from 'zod';

export const formTemplatesCommonInformationFormElementSchema = z.object({
	type: z.literal('information'),
	text: z.array(z.any()).min(1),
});

export const formTemplatesCommonTextQuestionFormElementSchema = z.object({
	type: z.literal('textQuestion'),
	question: z.string().min(1),
	isRequired: z.boolean(),
	answer: z.string().min(1).nullable().optional(),
	description: z.string().optional(),
	comment: z.string().min(1).nullable().optional(),
});

export const formTemplatesCommonYesNoQuestionFormElementSchema = z.object({
	type: z.literal('yesNoQuestion'),
	question: z.string().min(1),
	isRequired: z.boolean(),
	answer: z.boolean().nullable().optional(),
	description: z.string().optional(),
	comment: z.string().min(1).nullable().optional(),
});

export const formTemplatesCommonMultipleChoiceQuestionFormElementSchema = z.object({
	type: z.literal('multipleChoiceQuestion'),
	question: z.string().min(1),
	choices: z.array(z.object({ value: z.string().min(1) })).min(1),
	isRequired: z.boolean(),
	answer: z.array(z.boolean().nullable().default(false)).nullable().optional(),
	description: z.string().optional(),
	comment: z.string().min(1).nullable().optional(),
	allowMultipleAnswers: z.boolean(),
});

export const formTemplatesCommonApprovalQuestionFormElementSchema = z.object({
	type: z.literal('approvalQuestion'),
	question: z.string().min(1),
	answer: z.literal(true).nullable().optional(),
	description: z.string().optional(),
});

export const formTemplatesCommonAttachmentFormElementSchema = z.object({
	type: z.literal('attachment'),
	files: z
		.array(
			z.object({
				fileId: z.string().min(1),
				metadata: z.object({
					name: z.string(),
					size: z.number(),
					type: z.string(),
				}),
			}),
		)
		.min(1),
	comment: z.string().min(1).nullable().optional(),
	question: z.string().optional(),
	description: z.string().optional(),
});

export const formTemplatesCommonUploadFormElementSchema = z.object({
	type: z.enum(['fileUpload', 'imageUpload']),
	answer: z.array(
		z.object({
			fileId: z.string().min(1),
			metadata: z.object({
				name: z.string(),
				size: z.number(),
				type: z.string(),
			}),
		}),
	),
	isRequired: z.boolean(),
	question: z.string().min(1),
	description: z.string().optional(),
	comment: z.string().min(1).nullable().optional(),
});

export const formTemplatesCommonBuildFormContentInputSchema = z.object({
	sections: z.array(
		z.object({
			name: z.string().min(1),
			elements: z.array(
				z.discriminatedUnion('type', [
					formTemplatesCommonInformationFormElementSchema,
					formTemplatesCommonTextQuestionFormElementSchema,
					formTemplatesCommonYesNoQuestionFormElementSchema,
					formTemplatesCommonMultipleChoiceQuestionFormElementSchema,
					formTemplatesCommonApprovalQuestionFormElementSchema,
					formTemplatesCommonAttachmentFormElementSchema,
					formTemplatesCommonUploadFormElementSchema,
				]),
			),
		}),
	),
});

export const formTemplatesCommonViewFormContentInputSchema = z.object({
	sections: z.array(
		z.object({
			name: z.string().min(1),
			elements: z
				.array(
					z.discriminatedUnion('type', [
						formTemplatesCommonInformationFormElementSchema,
						formTemplatesCommonTextQuestionFormElementSchema,
						formTemplatesCommonYesNoQuestionFormElementSchema,
						formTemplatesCommonMultipleChoiceQuestionFormElementSchema,
						formTemplatesCommonApprovalQuestionFormElementSchema,
						formTemplatesCommonAttachmentFormElementSchema,
						formTemplatesCommonUploadFormElementSchema,
					]),
				)
				.superRefine((elements, ctx) => {
					elements.forEach((element, idx) => {
						switch (element.type) {
							case 'textQuestion':
								if (element.isRequired && !element.answer) {
									ctx.addIssue({
										code: z.ZodIssueCode.invalid_type,
										expected: 'string',
										received: 'undefined',
										path: [idx, 'answer'],
										fatal: true,
									});
								}
								break;
							case 'yesNoQuestion':
								if (element.isRequired && (element.answer === null || element.answer === undefined)) {
									ctx.addIssue({
										code: z.ZodIssueCode.invalid_type,
										expected: 'boolean',
										received: 'undefined',
										path: [idx, 'answer'],
										fatal: true,
									});
								}
								break;
							case 'multipleChoiceQuestion':
								if (element.isRequired && !element.answer?.some((el) => el)) {
									ctx.addIssue({
										code: z.ZodIssueCode.invalid_type,
										expected: 'boolean',
										received: 'undefined',
										path: [idx, 'answer'],
										fatal: true,
									});
								}
								break;
							case 'approvalQuestion':
								if (element.answer !== true) {
									ctx.addIssue({
										code: z.ZodIssueCode.invalid_type,
										expected: 'boolean',
										received: 'undefined',
										path: [idx, 'answer'],
										fatal: true,
									});
								}
								break;

							case 'fileUpload':
								if (element.isRequired && !element.answer.some((el) => el.fileId)) {
									ctx.addIssue({
										code: z.ZodIssueCode.invalid_type,
										expected: 'string',
										received: 'undefined',
										path: [idx, 'answer'],
										fatal: true,
									});
								}
								break;
							case 'imageUpload':
								if (element.isRequired && !element.answer.some((el) => el.fileId)) {
									ctx.addIssue({
										code: z.ZodIssueCode.invalid_type,
										expected: 'string',
										received: 'undefined',
										path: [idx, 'answer'],
										fatal: true,
									});
								}
								break;
							case 'information':
								break;
							case 'attachment':
								break;
							default: {
								const _exhaustive: never = element;
							}
						}
					});
				}),
		}),
	),
});

export const formTemplatesCommonInsertFormTemplateSchemaInput = z.object({
	name: z.string().min(1),
	type: z.enum(['CASE', 'INSPECTION']),
	formContent: formTemplatesCommonBuildFormContentInputSchema,
	published: z.boolean(),
});

export type ViewFormContentType = z.infer<typeof formTemplatesCommonViewFormContentInputSchema>;
export type BuildFormContentType = z.infer<typeof formTemplatesCommonBuildFormContentInputSchema>;

export type FormTemplateInsertableContent = Insertable<DB['formTemplate']>['formContent'];
export type FormTemplateUpdateableContent = Updateable<DB['formTemplate']>['formContent'];
